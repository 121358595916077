import { Location } from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Subscription} from 'rxjs';
import { LanguageHalItem } from '../../../../shared/hal/language.hal.item';
import { SubscriptionsContainer } from '../../../../shared/helper/SubscriptionsContainer';
import { LanguageServiceImpl } from '../../../../shared/services/language.service';

@Component({
  selector: 'fes-language-detail',
  templateUrl: './language-detail.component.html',
  styleUrls: ['./language-detail.component.scss']
})
export class LanguageDetailComponent implements OnInit, OnDestroy {
  selectedLanguageId: string = null;
  loading = false;
  language: LanguageHalItem;
  activeMode = [
    {label: 'Nein', value: false},
    {label: 'Ja', value: true}
  ];
  displayName: String = ""; // needed to access the activation for a language
  isEditable: boolean = false;

  subscriptionContainer: SubscriptionsContainer = new SubscriptionsContainer();

  @Input() isNew = false;


  constructor(
    private location: Location,
    private route: ActivatedRoute,
    public languageService: LanguageServiceImpl,
  ) {
    this.selectedLanguageId = this.route.snapshot.paramMap.get('id');
    this.getLanguageById(this.selectedLanguageId);
  }

  ngOnInit() {
    this.selectedLanguageId = this.route.snapshot.paramMap.get('id');
    this.getLanguageById(this.selectedLanguageId);
  }

  getLanguageById(id: string) {
    this.loading = true;
    this.subscriptionContainer.add = this.languageService.getById(id).subscribe(
      (language: LanguageHalItem) => {
        this.loading = false;
        this.language = language;
        this.displayName = this.language.data.displayName;
      }
    );
  }

  selectActiveMode(event: { value: any; }) {
    this.language.data.active = event.value;
     this.subscriptionContainer.add = this.languageService.update(this.language.data).subscribe(updatedLanguage => {
      //If active is set true successfully, all Neposia forms will be prepared to get translated
      if (updatedLanguage.data.active == true )
        this.languageService.translateAllNeposiaForms(updatedLanguage.data).subscribe();
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscriptionContainer.dispose();
    this.displayName = "";
  }

  saveData = (value: any, component: any) => {
    this.displayName = this.language.data.displayName;
    //Set active to false, if displayname ist empty
    if(this.language.data.displayName === "")
      this.language.data.active = false;

    return this.language.save(this.language.data);

  }

}
