import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { FileUpload } from 'primeng/primeng';
import { FesWorkflowActionComponent } from '../fes-workflow-action';
import {NgIfContext} from "@angular/common";
import {FileServiceImpl} from "../../../../shared/services/file.service";
import {ActionContextHalItem} from "@dsvs/workflow-generator-dto";

@Component({
  selector: 'fes-workflow-action-comment-add',
  templateUrl: './fes-workflow-action-comment-add.component.html',
  styleUrls: ['./fes-workflow-action-comment-add.component.scss']
})
export class FesWorkflowActionCommentAddComponent extends FesWorkflowActionComponent implements OnInit {

  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @ViewChild('fileuploader') fileuploader: any;

  files = [];

  constructor(messageService: MessageService, private fileService: FileServiceImpl) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        comment: new FormControl(''),
        files: new FormControl(null)
      });
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  public collectActionData(): WorkflowActionDataDto {
    if (this.inputForm.value.files != null) {
      return <WorkflowActionDataDto>{
        comment: this.inputForm.value.comment,
        file1: (this.inputForm.value.files && this.inputForm.value.files.length > 0) ? this.inputForm.value.files[0] : null,
        file2: (this.inputForm.value.files && this.inputForm.value.files.length > 1) ? this.inputForm.value.files[1] : null,
        file3: (this.inputForm.value.files && this.inputForm.value.files.length > 2) ? this.inputForm.value.files[2] : null,
        file4: (this.inputForm.value.files && this.inputForm.value.files.length > 3) ? this.inputForm.value.files[3] : null,
        file5: (this.inputForm.value.files && this.inputForm.value.files.length > 4) ? this.inputForm.value.files[4] : null,
        file6: (this.inputForm.value.files && this.inputForm.value.files.length > 5) ? this.inputForm.value.files[5] : null,
        file7: (this.inputForm.value.files && this.inputForm.value.files.length > 6) ? this.inputForm.value.files[6] : null,
        file8: (this.inputForm.value.files && this.inputForm.value.files.length > 7) ? this.inputForm.value.files[7] : null,
        file9: (this.inputForm.value.files && this.inputForm.value.files.length > 8) ? this.inputForm.value.files[8] : null,
        file10: (this.inputForm.value.files && this.inputForm.value.files.length > 9) ? this.inputForm.value.files[9] : null
      };
    } else {
      return <WorkflowActionDataDto>{
        comment: this.inputForm.value.comment
      };
    }
  }

  isValid(): boolean {
    return !!this.inputForm.value.comment;
  }

  onFileChange(target) {
    this.files.push(target);
    this.inputForm.get('files').setValue(this.files);
    if (this.fileChanged != null) {
      this.fileChanged.emit();
    }
    this.fileuploader.fileuploader.clear();
    /*if (target.files.length > 0) {
      const file = target.files[0];
      this.inputForm.get('file').setValue(file);

      if (this.fileChanged != null) {
        this.fileChanged.emit();
      }
    }*/
  }

  onFileRemoved(file) {
    //this.fileRemoved.emit();
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  resetForm() {
    this.files =[];
    this.fileuploader.fileuploader.clear();
    this.inputForm.reset();
  }


}
